<template>
  <div class="container">
    <v-container>
      <v-row align="center" justify="center">
        <v-col :lg="6" :sm="12">
          <v-card class="elevation-6 mt-12 rounded-xl">
            <v-row>
              <v-col cols="12" sm="12">
                <v-card-title align="center" class="mt-4">
                  <v-carousel height="100" :show-arrows="false" hide-delimiters cycle interval="2500">
                    <!-- <v-carousel-item>
                      <v-img src="/img/a_tu_lado.png" width="300" height="auto"></v-img>
                    </v-carousel-item> -->
                    <v-carousel-item>
                      <v-img src="/img/apoyandote_epilepsia.png" width="auto" height="auto"></v-img>
                    </v-carousel-item>
                    <v-carousel-item>
                      <v-img src="/img/apoyandote.png" width="300" height="auto"></v-img>
                    </v-carousel-item>
                    <v-carousel-item>
                      <v-img src="/img/asi_de_cerca.png" width="300" height="auto"></v-img>
                    </v-carousel-item>
                  </v-carousel>
                </v-card-title>
                <v-card-text class="mt-2">
                  <h2 class="text-center text-primary-lighten-1">
                    ¡Bienvenido a nuestro programa!
                  </h2>
                  <h4 class="text-center grey--text">
                    <br />
                    Ingresa tus datos y te contactaremos

                  </h4>
                  <h4 class="text-center grey--text">
                    O comuníquese con nosotros al teléfono 
                    <a href="tel:+56229447482">229447482</a>
                  </h4>
                  <v-row align="center" justify="center">
                    <v-col cols="12" sm="8">
                      <v-form ref="form" @submit.prevent="submitForm">
                        <v-list>
                          <v-combobox label="Medicamento *" base-color="black" :items="data.medicamentos" color="blue"
                            v-model="data.programaMedicamento"
                            :rules="[data.required('Medicamento'), data.validarMedicamento, data.counter(70)]"
                            maxlength="70" counter></v-combobox>
                          <v-text-field v-model="data.nombresPaciente" label="Nombres *"
                            :rules="[data.required('Nombres'), data.counter(100)]" outlined dense color="blue"
                            autocomplete="false" maxlength="100" counter required />
                          <v-text-field v-model="data.apellidosPaciente" label="Apellidos *"
                            :rules="[data.required('Apellidos'), data.counter(100)]" outlined dense color="blue"
                            autocomplete="false" maxlength="100" counter />
                          <v-text-field v-model="data.telefonoPaciente1" label="Teléfono 1 *"
                            :rules="[data.required('Teléfono 1'), data.counter(10)]" outlined dense color="blue"
                            autocomplete="false" counter maxlength="10" type="number"/>
                          <v-text-field v-model="data.telefonoPaciente2" label="Teléfono 2" outlined dense color="blue"
                            :rules="[data.counter(10)]" autocomplete="false" type="number" maxlength="10" counter />
                            <v-text-field v-model="data.nombreTutor" label="Nombre tutor"
                            :rules="[data.counter(200)]" outlined dense
                            color="blue" autocomplete="false" maxlength="200" counter />
                          <v-text-field v-model="data.telefonoTutor" label="Teléfono tutor" outlined dense color="blue"
                            :rules="[data.counter(10)]" autocomplete="false" maxlength="10" type="number" counter />
                          <v-text-field v-model="data.nombreMedicoTratante" label="Nombre médico tratante *"
                            :rules="[data.required('Nombre médico tratante'), data.counter(200)]" outlined dense
                            color="blue" autocomplete="false" maxlength="200" counter />
                          <v-text-field v-model="data.email" label="Email *"
                            :rules="[data.required('Email'), data.emailRules, data.counter(200)]" outlined dense
                            color="blue" autocomplete="false" type="email" maxlength="200" counter />
                          <v-combobox label="Comuna de residencia" hint="Nota: Seleccione o escriba en su teclado"
                            v-model="data.comuna" persistent-hint small-chips hide-selected :items="comunas_chile"
                            :hide-no-data="false" color="blue" class="mb-4" maxlength="150" :rules="[data.counter(150)]"
                            counter />
                          <v-textarea clearable label="Observaciones" v-model="data.observaciones" color="blue"
                            clear-icon="mdi-close-circle" maxlength="300" :rules="[data.counter(300)]" counter />
                          <v-file-input accept="image/*, .pdf" v-model:model-value="data.file"
                            label="Seleccionar archivo" @change="onFileChange(data.file)" />
                          <v-checkbox v-model="data.consentimiento">
                            <template v-slot:label>
                              <div>
                                Acepto los
                                <a href="#" @click.stop.prevent="formProp.dialog = true">
                                  Términos y Condiciones
                                </a>
                                *
                                <v-dialog v-model="formProp.dialog" max-width="600">
                                  <v-card>
                                    <v-card>
                                      <v-card-title class="headline">DECLARACIÓN</v-card-title>
                                      <v-card-text>
                                        <v-scroll-y-transition>
                                          <div class="declaration-content">
                                            <p>Acepto libre y voluntariamente a participar del programa de soporte a
                                              pacientes del laboratorio Biopas, y comprendo que este Programa no
                                              sustituye ni reemplaza el consejo y/o instrucciones de mi médico tratante
                                              relacionadas con mi salud, tratamiento, o los medicamentos que me han sido
                                              prescritos. Por lo anterior, manifiesto mi disposición y autorización para
                                              que me contacten para recibir los beneficios que otorga el programa de
                                              soporte a pacientes del laboratorio Biopas.</p>
                                            <p>Reconozco que al participar del Programa, el administrador del Programa
                                              estará tratando mi información personal y sensible, por lo que es
                                              responsable del tratamiento de dichos datos. Biopas no tendrá acceso a mi
                                              información personal identificable, pero podrá tener acceso a información,
                                              previamente anonimizada, sobre eventos adversos que pudieran ocurrir
                                              durante el tratamiento con el medicamento.</p>
                                            <p>En consecuencia, autorizo al administrador del Programa para recoger y
                                              analizar la información suministrada por mi o por mi representante legal
                                              de este Programa, con el propósito de prestar los servicios del Programa y
                                              notificar a Biopas y a terceros que actúen en su nombre, los eventos
                                              adversos ocurridos durante el uso de medicamento y dar a conocer esta
                                              información como lo requiere la ley o regulación de la autoridad sanitaria
                                              en Chile y en otros países del mundo.</p>
                                            <p>Asimismo, otorgo mi autorización para ser contactado por el administrador
                                              del Programa para cualquier tema relacionado con este Programa;
                                              seguimiento para siguientes administraciones, actualizaciones relacionadas
                                              con el Programa, realizar seguimiento de algún efecto adverso reportado,
                                              envío periódico de información, mensajes de adherencia e invitaciones a
                                              actividades relacionadas con el Programa.</p>
                                            <p>El administrador del Programa se obliga a cuidar mis datos con la debida
                                              diligencia y a cumplir con la Ley N°19.628 sobre la Protección de la Vida
                                              Privada.</p>
                                            <p>En cualquier momento puedo solicitar el acceso a mis datos, corregirlos y
                                              eliminarlos (sujeto a las obligaciones legales y/o reglamentarias
                                              impuestas al administrador del Programa), llamando al (2) 29447482 En el
                                              último caso se entenderá que renuncio al beneficio entregado por el
                                              Programa de Soporte a Pacientes Apoyándote de Neupro. También se
                                              considerará como retiro del Programa, si este no consigue establecer
                                              contacto durante 3 meses consecutivos.</p>
                                            <p>Al enviar y completar el formulario acepto las condiciones antes
                                              mencionadas.</p>
                                          </div>
                                        </v-scroll-y-transition>
                                      </v-card-text>
                                    </v-card>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="primary" text="Cerrar" @click="formProp.dialog = false"></v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </div>
                            </template>
                          </v-checkbox>
                        </v-list>
                        <v-btn type="submit" color="blue" dark block tile :disabled="formProp.loading"
                          :loading="formProp.loading">
                          Registrar
                        </v-btn>
                        <v-snackbar v-model="formProp.snackbar">
                          {{ formProp.snackbarText }}
                          <template v-slot:actions>
                            <v-btn color="blue" variant="text" @click="formProp.snackbar = false">
                              <v-icon icon="mdi-close"></v-icon>
                            </v-btn>
                          </template>
                        </v-snackbar>
                      </v-form>
                      <p>* Campos obligatorios</p>
                      <div class="d-flex justify-space-between align-center mx-10 mb-16"></div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { VForm } from "vuetify/lib/components/index.mjs";

useHead({
  title: "BIOPAS | Registro de Pacientes",
});

const MAX_FILE_SIZE_MB = 15; // Tamaño máximo en MB
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Conversión a bytes

const comunas_chile = [
  "Arica",
  "Camarones",
  "Putre",
  "General Lagos",
  "Alto Hospicio",
  "Iquique",
  "Camiña",
  "Colchane",
  "Pica",
  "Pozo Almonte",
  "Huara",
  "Camina",
  "La Higuera",
  "La Serena",
  "Coquimbo",
  "Andacollo",
  "La Higuera",
  "Vicuña",
  "Illapel",
  "Canela",
  "Los Vilos",
  "Salamanca",
  "Ovalle",
  "Combarbalá",
  "Monte Patria",
  "Punitaqui",
  "Río Hurtado",
  "Limarí",
  "Ovalle",
  "Rapel",
  "Navidad",
  "Litueche",
  "La Estrella",
  "Pichilemu",
  "Marchihue",
  "Paredones",
  "San Fernando",
  "Chépica",
  "Chimbarongo",
  "Lolol",
  "Nancagua",
  "Palmilla",
  "Peralillo",
  "Placilla",
  "Pichidegua",
  "Peumo",
  "Pichidegua",
  "Quinta de Tilcoco",
  "Rancagua",
  "Codegua",
  "Coínco",
  "Coltauco",
  "Doñihue",
  "Graneros",
  "Las Cabras",
  "Machalí",
  "Malloa",
  "Mostazal",
  "Olivar",
  "Peumo",
  "Pichidegua",
  "Quinta de Tilcoco",
  "Rengo",
  "Requínoa",
  "San Vicente",
  "Pichilemu",
  "La Estrella",
  "Litueche",
  "Marchihue",
  "Paredones",
  "San Antonio",
  "Algarrobo",
  "Cartagena",
  "El Quisco",
  "El Tabo",
  "Santo Domingo",
  "San Antonio",
  "Melipilla",
  "María Pinto",
  "Curacaví",
  "Alhué",
  "Buin",
  "Calera de Tango",
  "Paine",
  "San Bernardo",
  "Puente Alto",
  "Pirque",
  "San José de Maipo",
  "Colina",
  "Lampa",
  "Tiltil",
  "San Pedro",
  "Cerrillos",
  "Cerro Navia",
  "Conchalí",
  "El Bosque",
  "Estación Central",
  "Huechuraba",
  "Independencia",
  "La Cisterna",
  "La Florida",
  "La Granja",
  "La Pintana",
  "La Reina",
  "Las Condes",
  "Lo Barnechea",
  "Lo Espejo",
  "Lo Prado",
  "Macul",
  "Maipú",
  "Ñuñoa",
  "Pedro Aguirre Cerda",
  "Peñalolén",
  "Providencia",
  "Pudahuel",
  "Quilicura",
  "Quinta Normal",
  "Recoleta",
  "Renca",
  "San Joaquín",
  "San Miguel",
  "San Ramón",
  "Santiago",
  "Vitacura",
  "El Monte",
  "Isla de Maipo",
  "Padre Hurtado",
  "Peñaflor",
  "Talagante",
  "Coelemu",
  "Coihueco",
  "Chillán",
  "Chillán Viejo",
  "Cobquecura",
  "Coelemu",
  "El Carmen",
  "Ninhue",
  "Ñiquén",
  "Pemuco",
  "Pinto",
  "Portezuelo",
  "Quillón",
  "Quirihue",
  "Ránquil",
  "San Carlos",
  "San Fabián",
  "San Ignacio",
  "San Nicolás",
  "Treguaco",
  "Yungay",
  "Alto Biobío",
  "Antuco",
  "Cabrero",
  "Laja",
  "Los Ángeles",
  "Mulchén",
  "Nacimiento",
  "Negrete",
  "Quilaco",
  "Quilleco",
  "San Rosendo",
  "Santa Bárbara",
  "Tucapel",
  "Yumbel",
  "Concepción",
  "Coronel",
  "Chiguayante",
  "Florida",
  "Hualqui",
  "Lota",
  "Penco",
  "San Pedro de la Paz",
  "Santa Juana",
  "Talcahuano",
  "Tomé",
  "Hualpén",
  "Lebu",
  "Arauco",
  "Cañete",
  "Contulmo",
  "Curanilahue",
  "Los Álamos",
  "Tirúa",
  "Angol",
  "Collipulli",
  "Curacautín",
  "Ercilla",
  "Lonquimay",
  "Los Sauces",
  "Lumaco",
  "Purén",
  "Renaico",
  "Traiguén",
  "Victoria",
  "Temuco",
  "Carahue",
  "Cunco",
  "Curarrehue",
  "Freire",
  "Galvarino",
  "Gorbea",
  "Lautaro",
  "Loncoche",
  "Melipeuco",
  "Nueva Imperial",
  "Padre las Casas",
  "Perquenco",
  "Pitrufquén",
  "Pucón",
  "Saavedra",
  "Teodoro Schmidt",
  "Toltén",
  "Vilcún",
  "Villarrica",
  "Cholchol",
  "Angol",
  "Collipulli",
  "Curacautín",
  "Ercilla",
  "Lonquimay",
  "Los Sauces",
  "Lumaco",
  "Purén",
  "Renaico",
  "Traiguén",
  "Victoria",
  "Valdivia",
  "Corral",
  "Lanco",
  "Los Lagos",
  "Máfil",
  "Mariquina",
  "Paillaco",
  "Panguipulli",
  "La Unión",
  "Futrono",
  "Lago Ranco",
  "Río Bueno",
  "Ancud",
  "Castro",
  "Chonchi",
  "Curaco de Vélez",
  "Dalcahue",
  "Puqueldón",
  "Queilén",
  "Quellón",
  "Quemchi",
  "Quinchao",
  "Osorno",
  "Puerto Octay",
  "Purranque",
  "Puyehue",
  "Río Negro",
  "San Juan de la Costa",
  "San Pablo",
  "Calbuco",
  "Cochamó",
  "Fresia",
  "Frutillar",
  "Llanquihue",
  "Los Muermos",
  "Maullín",
  "Puerto Montt",
  "Puerto Varas",
  "Alerce",
  "La Unión",
  "Lago Ranco",
  "Río Bueno",
  "Ancud",
  "Castro",
  "Chonchi",
  "Curaco de Vélez",
  "Dalcahue",
  "Puqueldón",
  "Queilén",
  "Quellón",
  "Quemchi",
  "Quinchao",
  "Osorno",
  "Puerto Octay",
  "Purranque",
  "Puyehue",
  "Río Negro",
  "San Juan de la Costa",
  "San Pablo",
  "Calbuco",
  "Cochamó",
  "Fresia",
  "Frutillar",
  "Llanquihue",
  "Los Muermos",
  "Maullín",
  "Puerto Montt",
  "Puerto Varas",
];

let form = ref(VForm);
let formProp = ref({
  snackbar: false,
  snackbarText: "",
  loading: false,
  dialog: false
});
let data = ref({
  required: (propertyType: any) => {
    return (v: string) =>
      (v && v.length > 0) || `El campo ${propertyType} es requerido`;
  },
  emailRules: (value: any) => {
    if (/.+@.+\..+/.test(value)) return true;

    return "Debe ingresar un email válido.";
  },
  counter: (max: any) => {
    return (value: any) => value.length <= max || `Excedió el máximo de ${max} caracteres`;
  },
  medicamentos: [
    'ASI DE CERCA - CERTOLIZUMAB PEGOL',
    'APOYANDOTE - ROTIGOTINA',
    'APOYANDOTE - EPILEPSIA',
  ],
  validarMedicamento: (value: any) => {
    return data.value.medicamentos.includes(value) || 'Debe seleccionar un medicamento válido';
  },
  nombresPaciente: "",
  apellidosPaciente: "",
  telefonoPaciente1: "",
  telefonoPaciente2: "",
  comuna: "",
  email: "",
  observaciones: "",
  nombreMedicoTratante: "",
  programaMedicamento: "",
  file: [],
  consentimiento: false,
  telefonoTutor: "",
  nombreTutor: "",
});

const clearForm = () => {
  form.value.reset();
  data.value.nombresPaciente = "";
  data.value.apellidosPaciente = "";
  data.value.email = "";
  data.value.telefonoPaciente1 = "";
  data.value.telefonoPaciente2 = "";
  data.value.file = [];
  data.value.programaMedicamento = "";
  data.value.observaciones = "";
  data.value.nombreMedicoTratante = "";
  data.value.comuna = "";
  data.value.consentimiento = false;
  data.value.telefonoTutor = "",
  data.value.nombreTutor = ""
};

const getBase64 = async (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const submitWithFile = async (imgBase64: any) => {
  return useFetch("/api/mailer", {
    method: "POST",
    body: JSON.stringify({ ...data.value, file: imgBase64 }),
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 15000
  });
};

const submitWithoutFile = async () => {
  return useFetch("/api/mailer", {
    method: "POST",
    body: JSON.stringify({
      ...data.value,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 15000
  });
};

const submitForm = async () => {
  try {
    if (data.value.consentimiento == false) {
      formProp.value.snackbar = true;
      formProp.value.snackbarText =
        "Debe aceptar los términos y condiciones para continuar.";
      return;
    }
    formProp.value.loading = true;
    if (form.value) {
      const valid = await form.value.validate();
      if (valid.valid) {
        if (data.value.file != null && data.value.file.length > 0) {
          const imgBase64 = await getBase64(data.value.file[0]);

          const response = await submitWithFile(imgBase64);

          formProp.value.snackbar = true;
          if (response.status.value == "success") {
            formProp.value.snackbarText = "Paciente registrado exitosamente.";
            clearForm();
          } else {
            formProp.value.snackbarText =
              "Error al registrar el nuevo paciente.";
          }
        } else {
          const response = await submitWithoutFile();

          formProp.value.snackbar = true;
          if (response.status.value == "success") {
            formProp.value.snackbarText = "Paciente registrado exitosamente.";
            clearForm();
          } else {
            formProp.value.snackbarText =
              "Error al registrar el nuevo paciente.";
          }
        }
      } else {
        formProp.value.snackbar = true;
        formProp.value.snackbarText = "Formulario inválido.";
      }
    }
  } catch (error) {
    formProp.value.snackbarText = "Formulario inválido.";
  } finally {
    formProp.value.loading = false;
  }
};

const onFileChange = (file: any) => {
  if (file && file[0]) {
    const selectedFile = file[0];
    if (selectedFile.size > MAX_FILE_SIZE_BYTES) {
      formProp.value.snackbar = true;
      formProp.value.snackbarText = `El archivo seleccionado es demasiado grande. El tamaño máximo permitido es ${MAX_FILE_SIZE_MB} MB.`;
      data.value.file = []; // Resetea el valor de file
    } else {
      data.value.file = file;
    }
  } else {
    data.value.file = [];
  }
};
</script>
<style scoped>
.container {
  background-image: linear-gradient(white, lightblue);
}

.declaration-content {
  max-height: 600px;
  /* Ajusta la altura máxima del contenido aquí */
  overflow-y: auto;
  color: black;
  padding: 20px;
  font-size: 14px;
}

.declaration-content p {
  margin-bottom: 1em;
}
</style>
